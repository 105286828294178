<template>
  <div>
    <table-page data-name="list"
                ref="table"
                @on-success="fetchSuccess"
                :params="{ activity_id: $route.query.id }"
                :search="search"
                :request="luckyLogisticsList">
      <template slot="button">
        <FileExport :params="exportParams"
                    v-if="exportParams">导出数据</FileExport>
        <FileUpload type="primary"
                    class="mr-15"
                    @on-success="$refs.table.fetch()"
                    buttonType="icon"
                    :params="{import_type:'lucky_log_logistics_data',carry_data:{activity_id:$route.query.id}}">导入物流</FileUpload>
        <el-button icon="el-icon-download"
                   class="mr-15"
                   @click="download">下载导入模板</el-button>
      </template>
      <el-table-column prop="tel"
                       show-overflow-tooltip
                       label="手机号码"></el-table-column>
      <el-table-column prop="take_tel"
                       show-overflow-tooltip
                       label="收件人手机号"></el-table-column>
      <el-table-column prop="logistics_no"
                       show-overflow-tooltip
                       label="物流号"></el-table-column>
      <el-table-column prop="id"
                       show-overflow-tooltip
                       label="订单ID"></el-table-column>
    </table-page>
  </div>
</template>

<script>
import TablePage from '@/components/TablePage'
import FileExport from '@/components/FileExport'
import FileUpload from '@/components/FileUploadAsync'
import { luckyLogisticsList } from '@/api/market'
export default {
  name: 'MemberAppointRecord',
  data () {
    return {
      luckyLogisticsList,
      dialogVisible: false,
      luckyQrcodesExport_url: '',
      luckyLogExport_url: '',
      exportParams: null,
      search: [{
        type: 'select',
        key: 'search_type',
        name: '搜索类型',
        value: '',
        list: [{
          value: 'id',
          label: '订单ID'
        }, {
          value: 'tel',
          label: '手机号码'
        },
        {
          value: 'take_tel',
          label: '收件人手机号'
        },
        {
          value: 'logistics_no',
          label: '物流号'
        }]
      }, {
        type: 'input',
        value: '',
        key: 'search_keyword',
        placeholder: '请输入关键字'
      }]
    }
  },
  components: {
    TablePage,
    FileExport,
    FileUpload
  },
  methods: {
    fetchSuccess (e) {
      this.exportParams = e.export
    },
    download () {
      window.open('/data/logistics/物流导入模板.xlsx', '_blank')
    }
  },
  mounted () {

  }
}
</script>

<style lang="less" scoped></style>
