<template>
  <div style="display: inline-block;">
    <el-button type="text"
               @click="exportData"
               v-if="type ==='text'">
      <slot></slot>
    </el-button>
    <el-button icon="el-icon-download"
               v-else
               @click="exportData">
      <slot></slot>
    </el-button>
    <el-dialog title="导出状态"
               modal
               :close-on-click-modal='false'
               @close="clearTimer"
               :visible.sync="dialogVisible"
               width="50%">
      <div v-loading="status === 'UPLOADING'">
        <template v-if="status === 'SUBMITED'">
          <div>正在提交请求，请稍等</div>
        </template>
        <template v-else-if="status === 'PENDING'">
          <el-row>正在导出，请勿关闭</el-row>
          <el-progress :text-inside="true"
                       class="mt-15"
                       :stroke-width="24"
                       :percentage="percent"
                       status="success"></el-progress>
        </template>
        <template v-else-if="status === 'NORMAL'">
          <el-row>
            导出成功,请保存好下载文件
            <a :href="exportLink">点击直接下载</a>
          </el-row>
        </template>

        <template v-else-if="status === 'FAIL'">{{ errMsg }}</template>
      </div>

      <span slot="footer"
            class="dialog-footer">
        <el-button @click="clearTimer">取 消</el-button>
        <el-button type="primary"
                   @click="clearTimer">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import { exportStatus, exportSubmit } from '@/api'
export default {
  props: {
    params: Object,
    type: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      dialogVisible: false,
      exportId: '',
      total: 0,
      runTotal: 0,
      status: 'SUBMITED',
      exportLink: '',
      errMsg: '', // 异常消息
      timer: null // 计时器
    }
  },
  created () {

  },
  mounted () {

  },
  computed: {
    percent () {
      if (this.total > 0) {
        return parseInt(((this.runTotal / this.total) * 100).toFixed(0))
      } else {
        return 0
      }
    }
  },
  methods: {
    clearTimer () {
      this.dialogVisible = false
      clearTimeout(this.timer)
    },
    exportData () {
      this.dialogVisible = true
      this.exportSubmit()
    },
    /**
     * 导出文件
     */
    async exportSubmit () {
      if (!this.params) {
        this.status = 'FAIL'
        this.errMsg = '请求参数有误，请检查'
        return
      }
      const res = await exportSubmit({
        export_type: this.params.export_type,
        carry_data: this.params.carry_data,
        search_data: this.params.search_data
      })
      const exportData = res.data.export_data

      this.dialogVisible = true
      if (res.meta.code === 0) {
        this.status = exportData.status
        if (exportData.status === 'SUBMITED' || exportData.status === 'PENDING') {
          this.getExportStatus()
        } else {
          this.exportLink = exportData.download_url
        }
      } else if (res.meta.code === 4010) {
        this.status = exportData.status
        if (exportData.status === 'SUBMITED' || exportData.status === 'PENDING' || exportData.status === 'UPLOADING') {
          this.total = exportData.total
          this.runTotal = exportData.run_total
          this.getExportStatus()
        }
      } else {
        this.status = 'FAIL'
        this.errMsg = res.meta.msg
      }
    },
    /**
     * 查询导出状态
     */
    async getExportStatus () {
      const res = await exportStatus({
        export_type: this.params.export_type,
        carry_data: this.params.carry_data,
        search_data: this.params.search_data
      })
      if (res.meta.code === 0) {
        const exportData = res.data.export_data
        this.status = exportData.status
        if (exportData.status === 'SUBMITED') {
          this.timer = setTimeout(() => {
            this.getExportStatus()
          }, 1000)
        } else if (exportData.status === 'PENDING') {
          this.runTotal = exportData.run_total
          this.total = exportData.total
          this.timer = setTimeout(() => {
            this.getExportStatus()
          }, 1000)
        } else if (exportData.status === 'NORMAL') {
          this.exportLink = exportData.download_url
          clearTimeout(this.timer)
        } else if (exportData.status === 'UPLOADING') {
          this.timer = setTimeout(() => {
            this.getExportStatus()
          }, 1000)
        } else {
          this.status = 'FAIL'
          this.errMsg = res.data.export_data.err_msg
          clearTimeout(this.timer)
        }
      } else {
        this.status = 'FAIL'
        this.errMsg = res.meta.msg
        clearTimeout(this.timer)
      }
    }
  }
}
</script>

<style scoped lang="scss">
</style>
